<template>
  <div class="colonne">
    <h2>{{ titre }}</h2>
    <div id="classement">
      <div
        v-for="(element, index) in topArray"
        :key="element"
        class="ligne element-classement"
      >
        <p class="titre-tres-large">{{ index + 1 }}.</p>
        <div class="colonne element-classement-contenu">
          <h3>
            <b>{{ element[cléLabel] }} </b> - {{ element[cléValeur] }}
          </h3>
          <p class="texte-moyen">{{ element[cléSousTexte] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "colonne",
  props: {
    titre: {
      type: String,
      required: true,
    },
    topArray: {
      type: Array,
      required: true,
    },
    cléLabel: {
      type: String,
      required: true,
    },
    cléValeur: {
      type: String,
      required: true,
    },
    cléSousTexte: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.colonne {
  gap: var(--dsem);
  justify-content: center;
  align-items: stretch;
  height: 100%;
  padding: var(--dsem2);
}

#classement {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.element-classement {
  gap: var(--dsem);
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.element-classement-contenu {
  gap: 0;
  align-items: flex-start;
  justify-content: center;
}

.element-classement::before,
.element-classement:not(:last-child) {
  align-self: stretch;
  content: "";
  border-bottom: 1px solid white;
}
</style>
