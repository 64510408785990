<template>
  <grilleStandard>
    <template v-slot:contenu>
      <article class="mentions-legales">
        <h1>Mentions légales</h1>
        <h3>ARTICLE 1 - ÉDITEUR DU SITE</h3>
        <p>
          Ce site est édité par la Fédération des Parcs Naturels Régionaux de
          France.
        </p>
        <h3>ARTICLE 2 - PROPRIÉTÉ INTELLECTUELLE</h3>
        <p>
          Le site de l’Observatoire de l’agroécologie et de l’alimentation des
          parcs s’appuie sur le portail de données SISTA du BASIC, qui utilise
          le logiciel de visualisation de données Microsoft Power BI. Les
          données présentées proviennent de bases publiques ; une partie de ces
          données a fait l’objet d’un traitement réalisé par le BASIC.
        </p>
        <p>
          Tout le contenu du site est soumis à la législation en vigueur sur le
          droit d'auteur. Conformément à l’article L. 122-4 du Code de la
          propriété intellectuelle française, il est interdit de reproduire,
          copier, modifier, transmettre, diffuser de toute manière que ce soit,
          même partiellement, sur tout type de support, tout élément provenant
          de ce site sans l’autorisation explicite et préalable de la Fédération
          des Parcs Naturels Régionaux de France. Toute reproduction,
          représentation ou utilisation autorisée d’un élément constitutif du
          site Web doit mentionner la phrase suivante : © 2023-2028 Fédération
          des Parcs Naturels Régionaux de France. Tous droits réservés.
          Conformément à l’article L.122-5, alinéa premier, du Code de la
          propriété intellectuelle, la reproduction d’un élément du site à des
          fins personnelles et pour un usage privé est autorisée.
        </p>
        <h3>ARTICLE 3 – CONTENU DU SITE</h3>
        <p>
          La Fédération des Parcs se réserve le droit de corriger, à tout moment
          et sans préavis, le contenu de ce site, et décline toute
          responsabilité en cas de retard, d’erreur ou d’omission quant au
          contenu des présentes pages de même qu’en cas d’interruption ou de
          non-disponibilité du service.
        </p>
        <h3>ARTICLE 4 – CONTENUS EXTERNES</h3>
        <p>
          Les liens hypertextes pointant vers le contenu des sites externes
          n'engagent en aucun cas la responsabilité de la Fédération des Parcs
          Naturels Régionaux de France.
        </p>
      </article>
    </template></grilleStandard
  >
</template>

<script>
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";

export default {
  name: "pageMentionsLegales",
  components: {
    grilleStandard,
  },
};
</script>

<style scoped>
.mentions-legales {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--dsem);
  padding: var(--dsem2) 0;
}

h3 {
  color: var(--couleur-primaire);
  font-weight: bold;
}
</style>
