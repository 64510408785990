<script>
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
export default {
  components: { grilleStandard },
};
</script>

<template>
  <grilleStandard>
    <template v-slot:contenu>
      <article class="contenu">
        <h2>Méthodologie</h2>

        <p style="color: windowtext">
          Le périmètre de certains Parcs ne se superpose pas totalement au
          périmètre administratif des communes. Il n’est donc pas toujours
          possible d’afficher les données pour le périmètre exact du Parc.&nbsp;
        </p>

        <p style="color: windowtext; text-align: center">
          <em
            >Une commune du PNR de l’Avesnois qui n’est pas située totalement
            dans le périmètre du Parc (contours en rouge)</em
          >
        </p>

        <p style="color: windowtext">
          Sur la page « Généralités » vous disposez d’un tableau pour savoir
          combien de communes ne sont pas entièrement comprises dans le
          périmètre du Parc. Cependant, sur cette page, le nombre de communes
          ainsi que la population sont bien affichés pour le territoire du
          Parc.&nbsp;
        </p>

        <p style="color: windowtext">
          Ensuite, pour les autres pages, en fonction de la disponibilité des
          données, deux types de données sont affichées.
        </p>

        <ul>
          <li>
            Pour l’ensemble des indicateurs, sauf ceux issus du RGA, les données
            sont affichées pour les communes dont au moins 50% de leur surface
            se situe dans le territoire du Parc,
          </li>
          <li>
            Dans le cas des données issues du RGA présentées dans la page
            exploitations agricoles, les données sont affichées pour toutes les
            communes du Parc quel que soit leur part dans le territoire du Parc.
          </li>
        </ul>
        <h2>Filtre « surfaces »</h2>
        <p>
          Au début de plusieurs pages, il existe un filtre qui permet de choisir
          la surface agricole étudier. Il est possible de distinguer :
        </p>
        <ul>
          <li>
            « Terres arables, prairies permanentes herbacées et jachères » d’un
            côté,
          </li>
          <li>« Surfaces pastorales hétérogènes » de l’autre.</li>
        </ul>
        <p>
          Il est ainsi possible de distinguer les terres qui peuvent être
          facilement mises en culture des terres qui sont, étant donné leur
          situation, des surfaces pastorales qui ne peuvent pas être simplement
          cultivées.
        </p>
        <h2>Données</h2>
        <p style="color: windowtext">
          L’ensemble des données provient de bases publiques disponibles à des
          niveaux infrarégionaux. Certaines données sont présentées sans avoir
          été retravaillées, d’autres sont issues de modélisations réalisées par
          le BASIC.&nbsp;
        </p>

        <p style="color: windowtext">
          L’ensemble des tables derrière chaque représentation graphique est
          téléchargeable en format .csv en cliquant sur les trois petits points
          qui s’affichent en haut à droite de la figure. Il est aussi possible
          d’afficher la figure en pleine page (« Mode focus »).&nbsp;
        </p>

        <table id="sources">
          <tbody>
            <tr>
              <th>
                <span style="">Base de données</span>
              </th>
              <th>
                <span style="">Utilisation&nbsp;</span>
              </th>
              <th>
                <span style="">Fréquence d’actualisation&nbsp;</span>
              </th>
              <th>
                <span style=""
                  >Dernière année disponible dans le tableau de bord&nbsp;</span
                >
              </th>
            </tr>
            <tr>
              <td>INSEE</td>
              <td>Contexte&nbsp;</td>
              <td>Annuel&nbsp;</td>
              <td>2018&nbsp;</td>
            </tr>
            <tr>
              <td>ICHN</td>
              <td>Contexte&nbsp;</td>
              <td>?</td>
              <td>2017&nbsp;</td>
            </tr>
            <tr>
              <td>RPG</td>
              <td>
                Surfaces agricoles Cheptels Paysages et IAE&nbsp; Usage des
                pesticides
              </td>
              <td>Annuel</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>RPG Bio</td>
              <td>Pratiques agroécologiques&nbsp;</td>
              <td>Annuel&nbsp;</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>RGA</td>
              <td>Cheptels Exploitations agricoles&nbsp;</td>
              <td>Tous les 10 ans&nbsp;</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>MSA&nbsp;</td>
              <td>Exploitations agricoles&nbsp;</td>
              <td>Annuel&nbsp;</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>Corinne Land Cover&nbsp;</td>
              <td>Paysages et IAE&nbsp;</td>
              <td>5 ans au mieux</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>TOPO de l’IGN</td>
              <td>Paysages et IAE</td>
              <td>
                Renouvellement sur l’ensemble des départements tous les 3 à 4
                ans
              </td>
              <td>2020</td>
            </tr>
            <tr>
              <td>Eau de France&nbsp;</td>
              <td>Eau</td>
              <td>Tous les 5 ans en moyenne</td>
              <td>2019</td>
            </tr>
            <tr>
              <td>Agence bio&nbsp;</td>
              <td>Pratiques agroécologiques&nbsp;</td>
              <td>Annuel&nbsp;</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>CEREMA</td>
              <td>Artificialisation&nbsp;</td>
              <td>Annuel&nbsp;</td>
              <td>2019</td>
            </tr>
            <tr>
              <td>BNVD</td>
              <td>Usage des pesticides</td>
              <td>Annuel</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>INSEE, SIRENE</td>
              <td>
                Milieu de chaine&nbsp; Distribution&nbsp; Restauration&nbsp;
                Potentiel agroalimentaire&nbsp;
              </td>
              <td>Annuel&nbsp;</td>
              <td>2021</td>
            </tr>
            <tr>
              <td>INSEE, DEE</td>
              <td>
                Milieu de chaine&nbsp; Distribution&nbsp; Restauration&nbsp;
              </td>
              <td>Annuel (?)</td>
              <td>2018</td>
            </tr>
          </tbody>
        </table>
      </article>
    </template>
  </grilleStandard>
</template>

<style scoped>
.contenu {
  text-align: left;
  h1,
  h2,
  p,
  ul {
    padding: var(--dsem);
  }
  ul {
    list-style: circle;
    list-style-position: inside;
  }
}
#sources {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#sources td,
#sources th {
  border: 1px solid #ddd;
  padding: 8px;
}

#sources tr:nth-child(even) {
  background-color: #f2f2f2;
}

#sources tr:hover {
  background-color: #ddd;
}

#sources th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--couleur-primaire);
  color: white;
}
</style>
