<template>
  <Transition name="fade">
    <div
      v-if="isActive"
      :class="{
        ongletInactif: isActive != true,
      }"
    >
      <slot />
    </div>
  </Transition>
</template>

<script>
export default {
  inject: ["ajouterOnglet", "activeOngletHash"],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return { hash: "", isActive: false, renderComponent: true };
  },
  created() {
    this.hash = "#" + this.title.toLowerCase().replace(/ /g, "-");

    this.ajouterOnglet({
      title: this.title,
      hash: this.hash,
    });
  },
  watch: {
    activeOngletHash() {
      this.isActive = this.activeOngletHash === this.hash;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.ongletInactif {
  opacity: 0;
}
</style>
