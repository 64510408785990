<script>
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
export default {
  components: { grilleStandard },
};
</script>

<template>
  <grilleStandard>
    <template v-slot:contenu>
      <article class="contenu">
        <h1>Contexte et objectifs</h1>
        <h2>Contexte</h2>
        <p style="color: windowtext">
          En entrant sur la page de navigation, vous pouvez choisir le Parc que
          vous souhaitez étudier. Des données synthétiques, notamment à
          destination des élus sont présentées. Ensuite vous pouvez décider de
          découvrir l’ensemble des données disponibles pour votre Parc en
          naviguant sur les différents tableaux de bord. Les données sont
          réparties dans 4 sections :&nbsp;
        </p>

        <ul>
          <li>Général</li>
          <li>Données agricoles</li>
          <li>Agroécologie&nbsp;</li>
          <li>Filières</li>
        </ul>

        <p style="color: windowtext">
          Chaque section est composée d’une ou plusieurs pages.&nbsp;
        </p>

        <p style="color: windowtext">
          La page « Contexte » présente des informations générales sur le
          territoire sélectionné (nombre de communes, démographie etc.). A ce
          stade, nous vous conseillons de bien vérifier que le périmètre proposé
          correspond à celui de votre territoire d'étude (vérification du nombre
          de communes notamment).
        </p>

        <p style="color: windowtext">
          Au sein de chaque page, vous pouvez redéfinir le périmètre de votre
          Parc en choisissant d’afficher les données pour certaines communes, ou
          bien, pour les Parcs Nationaux de choisir le périmètre cœur de Parc ou
          aire d’adhésion. Des filtres permettent de sélectionner en un clic
          toutes les communes d’une EPCI comprise dans un Parc. Certaines
          données ne sont pas disponibles à l’échelle communale, notamment les
          données issues du RGA (Recensement Général Agricole), ces filtres ne
          sont donc pas fonctionnels sur la page « Exploitations agricoles ». A
          noter que vous pouvez sélectionner plusieurs Parcs dans le menu
          « Choix du territoire ».
        </p>
        <p>
          Également, vous pouvez sélectionner un territoire de comparaison
          (région, France) : quelques chiffres et tendances clés s'y afficheront
          pour faciliter l'analyse des résultats. Attention : les filtres ne
          s’appliquent pas à ces indicateurs.&nbsp;
        </p>

        <p style="color: windowtext">
          Certains filtres en début de page s’appliquent à l’ensemble des
          données. Par exemple, pour certaines données agricoles, vous pouvez
          choisir d’afficher les données pour les cultures ou pour les surfaces
          toujours en herbe.&nbsp;
        </p>

        <p style="color: windowtext">
          D’autres filtres s’appliquent uniquement à certaines représentations :
          vous pouvez par exemple changer l’année d’une carte ou encore cibler
          une filière sur un graphique. Ces filtres ne s’appliquent pas à
          l’ensemble de la page.&nbsp;
        </p>

        <p style="color: windowtext">
          De plus, il existe 3 filtres qui permettent de faire une sélection
          globale :&nbsp;
        </p>

        <ul>
          <li>Ensemble des Parcs</li>
          <li>Ensemble des PNR</li>
          <li>Ensemble des PN&nbsp;</li>
        </ul>

        <p style="color: windowtext">
          L'outil est interactif et nous vous invitons à tester ses différentes
          fonctionnalités pour faciliter vos analyses. Par exemple, le passage
          de la souris sur les éléments cartographiés permet dans la plupart des
          cas d'afficher davantage de détails sur les valeurs associées à chaque
          commune.&nbsp;
        </p>

        <p style="color: windowtext">
          Quelques éléments que nous ne pouvons pas résoudre
          techniquement :&nbsp;
        </p>

        <ul>
          <li>
            Les légendes n'apparaissent pas toujours dans le bon ordre et leur
            valeur peuvent paraitre déroutantes dans certains cas : c'est un
            problème technique lié à l'utilisation de l’outil que nous utilisons
            pour organiser les pages que nous nous efforçons d'améliorer.&nbsp;
          </li>
        </ul>
      </article>
    </template>
  </grilleStandard>
</template>

<style scoped>
.contenu {
  text-align: left;
  h1,
  h2,
  p,
  ul {
    padding: var(--dsem);
  }
  ul {
    list-style: circle;
    list-style-position: inside;
  }
}
</style>
