<template>
  <main class="grille-container">
    <grilleStandard>
      <template v-slot:contenu>
        <grilleChiffres>
          <div id="agri">
            <colonne indicateur="CULTURE EMBLEMATIQUE" sousTexte="A VENIR" />
          </div>
          <div id="culture-emblematique">
            <div class="ligne">
              <div>
                <b class="titre-moyen" v-if="phraseIntro">{{ phraseIntro }}</b>
                <BoutonStandard
                  typeBouton="lien"
                  taille="texte-petit"
                  :href="sistaURL + '?code_du_territoire=' + codeParc"
                  >Découvrez le rapport détaillé</BoutonStandard
                >
              </div>
              <div>
                <img
                  v-if="typeParc === 'pnr'"
                  id="logo-parc"
                  :src="
                    require('@/assets/images/logo/' +
                      codeParc.substring(codeParc.length - 2) +
                      '.jpg')
                  "
                />
                <img
                  v-if="typeParc === 'pnx'"
                  style="max-width: 80%"
                  id="logo-parc"
                  :src="
                    require('@/assets/images/logo/' +
                      codeParc.substring(3).toLowerCase() +
                      '.jpg')
                  "
                />
              </div>
            </div>
          </div>
          <div id="occupation-sol">
            <classement
              :titre="`Les 3 principales occupations du sol`"
              :topArray="top3Cultures"
              :cléLabel="'culture'"
              :cléValeur="'chiffre'"
              :cléSousTexte="'part'"
              indicateur="Estives et Landes"
              sousTexte="Soit une évolution de -15% depuis 2010"
            />
          </div>
          <div
            class="carte"
            v-for="nbClé in NombresClés()"
            :key="nbClé.indicateur"
            v-if="donneespretes"
          >
            <colonne
              v-if="donneespretes"
              :chiffre="nbClé.chiffre"
              :indicateur="nbClé.indicateur"
              :sousTexte="nbClé.description"
              :format="nbClé.format"
              :source="nbClé.source"
            />
          </div>
        </grilleChiffres>
      </template>
    </grilleStandard>
  </main>
</template>
<script>
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import classement from "@packages/ui-components/src/composants/visualisation/gros-chiffre/classement.vue";
import colonne from "@packages/ui-components/src/composants/visualisation/gros-chiffre/colonne.vue";
import donnees_utils from "@/utils/donnees.js";
import formatter_utils from "@/utils/formatter.js";
import grilleChiffres from "@packages/ui-components/src/composants/visualisation/grilleChiffres.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import liste_parcs from "@/assets/donnees/liste_parcs.json";
import api_utils from "@/utils/api.js";
export default {
  name: "pageTestGrille",
  components: {
    BoutonStandard,
    classement,
    colonne,
    grilleChiffres,
    grilleStandard,
  },
  props: {
    codeParc: {
      type: String,
      default: "FR8000001",
    },
  },
  data() {
    return {
      code_du_territoire: "pnrFR8000001",
      RGAData2020: null,
      RGAData2010: null,
      RPGData: null,
      BasicData: null,
      territoiresData: null,
      top3Cultures: null,
      RPGDataParCulture: null,
      liste_parcs,
    };
  },
  methods: {
    async recupererDonnees() {
      this.RGAData2020 = await api_utils.getDonneesRGATerritoires(
        this.codeParc,
        2020,
        "otex"
      );
      this.RGAData2010 = await api_utils.getDonneesRGATerritoires(
        this.codeParc,
        2010,
        "otex"
      );
      this.RPGData = await api_utils.getDonneesRPGTerritoires(
        this.codeParc,
        2020
      );
      this.BasicData = await api_utils.getDonneesBasicTerritoires(
        this.codeParc
      );
      this.territoiresData = await api_utils.getDonneesTerritoires(
        this.codeParc
      );
      this.RPGDataParCulture = await api_utils.getDonneesRPGTerritoires(
        this.codeParc,
        2020,
        null,
        1
      );
      this.choisirTop3();
    },
    choisirTop3() {
      let sauParCulture =
        this.RPGDataParCulture["resultats"]["Surface Agricole Utile"];
      let sauParCultureArray = Object.entries(sauParCulture);
      sauParCultureArray.sort((a, b) => b[1] - a[1]);
      this.top3Cultures = sauParCultureArray.slice(0, 3);
      this.top3Cultures = this.top3Cultures.map((culture) => {
        return {
          culture: culture[0],
          chiffre: formatter_utils.formatterNombreFormatFR(culture[1]) + " ha",
          part:
            "Soit " +
            formatter_utils.formatPercent(
              culture[1] /
                this.RPGData["resultats"]["Surface Agricole Utile"]["2020"],
              0
            ) +
            " de la SAU du Parc",
        };
      });
    },
    NombresClés: function () {
      let pointApiRPG = this.RPGData["resultats"];
      let pointApiRGA2020 = this.RGAData2020["resultats"];
      let pointApiRGA2010 = this.RGAData2010["resultats"];
      let pointApiBasic = this.BasicData["resultats"];
      let pointApiTerritoires = this.territoiresData["resultats"];
      var resultat = [
        {
          indicateur: "Exploitations dont le siège est dans le parc",
          chiffre: formatter_utils.agregerChiffres(
            pointApiRGA2020["Nombre d'Exploitations"]
          ),
          image: "noun-farm-419654.svg",
          description: `Soit une évolution de ${donnees_utils.evolution(
            formatter_utils.agregerChiffres(
              pointApiRGA2010["Nombre d'Exploitations"]
            ),
            formatter_utils.agregerChiffres(
              pointApiRGA2020["Nombre d'Exploitations"]
            )
          )} depuis 2010`,
          source: this.RGAData2020["metadata"]["source"] + " 2020",
          format: "",
        },
        {
          indicateur: "de SAU dans le territoire",
          chiffre:
            pointApiRPG["Surface Agricole Utile"]["2020"] /
            pointApiTerritoires["Superficie totale du territoire"]["2018"],
          image: "BASIC_champs.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiTerritoires["Superficie totale du territoire"]["2018"]
          )} ha (superficie totale du parc)`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "de la SAU en bio",
          chiffre:
            pointApiRPG["SAU bio en hectares"]["2020"] /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "BASIC_data-1.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["SAU bio en hectares"]["2020"]
          )} parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} hectares de SAU`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "de la SAU toujours en herbe",
          chiffre:
            pointApiRPG["SAU toujours en herbe en hectares"]["2020"] /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "BASIC_data-1.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["SAU toujours en herbe en hectares"]["2020"]
          )} de STH parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} ha de SAU`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "Mètres linéaires par ha de SAU",
          chiffre:
            (pointApiBasic["Mètre linéaires de haies"]["2020"] * 1000) /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "noun-trees-5382698.svg",
          description: `À comparer aux 110m de hais recommandés par l'AFAC`,
          source: this.BasicData["metadata"]["source"] + " 2020",
          format: "",
        },
      ];
      return resultat;
    },
  },
  computed: {
    nomParc() {
      let parc = this.liste_parcs.find((parc) => {
        return parc.code === this.codeParc;
      });
      return parc.nom;
    },
    phraseIntro() {
      switch (this.codeParc) {
        case "pnrFEDERATION":
          return "Découvrez l'ensemble des PNR";
        case "pnxFEDERATION":
          return "Découvrez l'ensemble des Parcs nationaux";
        case "pnFEDERATION":
          return "Découvrez l'ensemble des Parcs";

        default:
          return `Bienvenue dans le ${this.nomParc}`;
      }
    },
    typeParc() {
      let parc = this.liste_parcs.find((parc) => {
        return parc.code === this.codeParc;
      });
      return parc.type;
    },
    donneespretes: function () {
      return (
        this.RGAData2020 &&
        this.RGAData2010 &&
        this.RPGData &&
        this.BasicData &&
        this.territoiresData
      );
    },
  },
  beforeMount() {
    this.recupererDonnees();
  },
};
</script>

<style scoped>
.grille-container {
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  padding: var(--dsem2) 0;
}
#agri {
  background-color: red;
  grid-column: 1 / 1;
  grid-row: 3 / 3;
}
#bio {
  background-color: white;
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#culture-emblematique {
  background-color: white;
  border: 1px solid var(--couleur-secondaire);
  grid-column: 3 / 5;
  grid-row: 1 / 1;
  padding: var(--dsem2);
  height: inherit;
  display: flex;
}

#logo-parc {
  max-width: 50%;
  max-height: 50%;
  margin: auto;
}
#occupation-sol {
  background-color: var(--couleur-primaire);
  opacity: 0.8;
  grid-column: 3 / 5;
  grid-row: 2 / 4;
  color: #ebebeb;
}

#culture-emblematique .ligne {
  justify-content: center;
  align-items: center;
}
#culture-emblematique .ligne div {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  align-items: center;
  padding: var(--dsem);
}

#culture-emblematique .ligne div:first-child {
  border-right: 1px solid var(--couleur-secondaire);
}

#chiffresSynthese {
  flex-basis: 100%;
}
</style>
