<template>
  <div id="pageAccueil">
    <grilleStandard>
      <template v-slot:contenu>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--dsem2);
          "
        >
          <h1 style="text-align: left">
            Pour accéder à l'Observatoire de l'agroécologie et de l'alimentation
            des Parcs, veuillez saisir un mot de passe
          </h1>
          <form v-on:submit.prevent="validateBeforeSubmit" class="formulaire">
            <div class="form-group text-left">
              <input
                class="formulaire-champ-mdp"
                type="password"
                name="password"
                v-model.trim="password"
              />
              <span class="error help-block"></span>
            </div>
            <boutonStandard typeBouton="input">
              Choisissez votre parc
            </boutonStandard>
          </form>
          <div class="text-danger" v-if="error">
            <p>Incorrect password.</p>
          </div>
        </div>
      </template>
    </grilleStandard>
    <img
      :src="require(`@/assets/images/hero/agroeco-vert.jpg`)"
      style="
        object-fit: cover;
        object-position: right;
        width: 100%;
        height: 180px;
        border: solid 1px #ccc;
      "
    />
  </div>
  <div id="carteParc" ref="carte">
    <grilleStandard>
      <template v-slot:contenu>
        <h2>Choisissez votre parc en cliquant sur son logo</h2>
        <div>
          <boutonStandard
            type="plat"
            typeBouton="lien"
            couleur="couleur-primaire"
            @click="goto('carte')"
          >
            Aller à la page de la fédération des Parcs
          </boutonStandard>
          <boutonStandard type="plat" typeBouton="lien" @click="goto('carte')">
            Explorer les Parcs nationaux
          </boutonStandard>
        </div>
        <carteParc />
      </template>
    </grilleStandard>
  </div>
</template>

<style scoped>
#pageAccueil {
  height: calc(100vh - var(--hauteur-barre-navigation));
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  gap: var(--dsem2);
}

#carteParc {
  height: calc(100vh - var(--hauteur-barre-navigation));
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  gap: var(--dsem2);
}

.formulaire {
  display: flex;
  flex-direction: row;
  gap: var(--dsem2);
}
input.formulaire-champ-mdp {
  width: 100%;
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid black;
  box-sizing: border-box;
}
</style>

<script>
import storageHelper from "storage-helper";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import carteParc from "@/composants/carteParc.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
export default {
  name: "pageTest",
  components: {
    BoutonStandard,
    carteParc,
    grilleStandard,
  },
  data() {
    return {
      error: null,
      password: null,
    };
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === "obagal-PN6424!") {
        this.error = false;
        storageHelper.setItem("user-password", this.password);
        this.$router.push("/");
      } else {
        this.error = true;
      }
    },
  },
};
</script>
