<template>
  <div id="pageAccueil">
    <grilleStandard>
      <template v-slot:contenu>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--dsem2);
          "
        >
          <h1 style="text-align: left">
            Découvrez l'Observatoire de l'agroécologie et de l'alimentation des
            Parcs
          </h1>
          <h3 style="text-align: left">
            Un observatoire pour alimenter la réflexion des
            <b style="color: var(--couleur-primaire)"
              >Parcs nationaux et des Parcs Naturels Régionaux</b
            >
            <br />
            en matière de
            <b style="color: var(--couleur-primaire)"
              >transition agroécologique et alimentaire, d’impacts sociaux et
              environnementaux</b
            >
          </h3>
          <boutonStandard typeBouton="lien" @click="goto('carte')">
            Choisissez votre parc
          </boutonStandard>
        </div>
      </template>
    </grilleStandard>
    <img
      :src="require(`@/assets/images/hero/agroeco-vert.jpg`)"
      style="
        object-fit: cover;
        object-position: right;
        width: 100%;
        height: 180px;
        border: solid 1px #ccc;
      "
    />
  </div>
  <div id="carteParc" ref="carte">
    <grilleStandard>
      <template v-slot:contenu>
        <h2>Choisissez votre parc en cliquant sur son logo</h2>
        <carteParc />
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          "
        >
          <boutonStandard
            type="plat"
            typeBouton="bouton"
            couleur="couleur-primaire"
            :lienRoute="{
              name: 'Page Synthétique Parc',
              params: { idParc: 'pnrFEDERATION' },
            }"
          >
            Explorez l'ensemble des PNR
          </boutonStandard>
          <boutonStandard
            type="plat"
            typeBouton="bouton"
            :lienRoute="{
              name: 'Page Synthétique Parc',
              params: { idParc: 'pnxFEDERATION' },
            }"
          >
            Explorer les Parcs nationaux
          </boutonStandard>
          <boutonStandard
            type="plat"
            typeBouton="bouton"
            couleur="couleur-primaire"
            :lienRoute="{
              name: 'Page Synthétique Parc',
              params: { idParc: 'pnFEDERATION' },
            }"
          >
            Explorez l'ensemble des Parcs
          </boutonStandard>
        </div>
      </template>
    </grilleStandard>
  </div>
</template>

<script>
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import carteParc from "@/composants/carteParc.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
export default {
  name: "pageTest",
  components: {
    BoutonStandard,
    carteParc,
    grilleStandard,
  },
  data() {
    return {};
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
  },
  mounted() {
    console.log(this.sistaURL);
  },
};
</script>

<style scoped>
#pageAccueil {
  height: calc(100vh - var(--hauteur-barre-navigation));
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  gap: var(--dsem2);
}

#carteParc {
  height: calc(100vh - var(--hauteur-barre-navigation));
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;
  gap: var(--dsem2);
}
</style>
