<template>
  <button :content="tooltipContenu" v-tippy="{ placement: 'top' }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24 "
      height="24"
      viewBox="0 0 50 50"
    >
      <!-- Transparent background -->
      <rect width="100%" height="100%" fill="transparent" />

      <!-- Black border -->
      <circle
        cx="25"
        cy="24"
        r="16"
        fill="transparent"
        stroke="#9a9997"
        stroke-width="2"
      />

      <!-- "i" symbol -->
      <text
        x="50%"
        y="50%"
        font-family="Futura Sans Pro, sans-serif"
        font-size="24"
        font-weight="semi-bold"
        fill="#9a9997"
        text-anchor="middle"
        alignment-baseline="middle"
      >
        i
      </text>
    </svg>
  </button>
</template>

<script>
import { directive } from "vue-tippy";

export default {
  props: {
    tooltipContenu: {
      type: String,
      required: true,
    },
  },
  directives: {
    tippy: directive,
  },
};
</script>

<style>
.tippy-content {
  background-color: black;
  color: white;
  padding: var(--dsem);
  font-size: 12px;
}
</style>
