<template>
  <div class="colonne">
    <h1 v-if="chiffre">{{ this.formatter(chiffre) }}</h1>
    <h3>{{ indicateur }}</h3>
    <p class="texte-petit">{{ sousTexte }}</p>
    <div class="ligne">
      <div class="information">
        <BoutonStandard
          v-if="montrerBouton"
          taille="texte-petit"
          style="height: 28px; padding: var(--dsem)"
        >
          <flecheDroite style="stroke: white" />
        </BoutonStandard>
      </div>
      <InformationTooltip :tooltipContenu="source" />
    </div>
  </div>
</template>

<script>
import formatter_utils from "@/utils/formatter.js";
import flecheDroite from "../../basNiveau/icones/flecheDroite.vue";
import BoutonStandard from "../../BoutonStandard.vue";
import InformationTooltip from "@packages/ui-components/src/composants/basNiveau/InformationTooltip.vue";

export default {
  name: "colonne",
  components: {
    BoutonStandard,
    flecheDroite,
    InformationTooltip,
  },
  props: {
    chiffre: {
      type: Number,
      required: true,
    },
    indicateur: {
      type: String,
      required: true,
    },
    sousTexte: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: "nombre",
    },
    source: {
      type: String,
      required: true,
    },
    montrerBouton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatter(chiffre) {
      if (this.format == "pourcentage") {
        return formatter_utils.formatPercent(chiffre);
      } else {
        return `${formatter_utils.formatterNombreFormatFR(chiffre)} ${
          this.format
        }`;
      }
    },
  },
};
</script>

<style scoped>
.colonne {
  gap: var(--dsem);
  padding: var(--dsem) var(--dsem2);
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background-color: #ebebeb;
  color: #9a9997;
}

.colonne h3,
p {
  text-align: left;
}

.ligne {
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.ligne .information {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
}
</style>
