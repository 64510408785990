<template>
  <div id="container">
    <grilleStandard style="height: fit-content; padding: var(--dsem2) 0">
      <template #contenu>
        <h1>Carte des OTEX principales par commune</h1>
      </template>
      <template #bordureDroite>
        <BoutonStandard
          typeBouton="lien"
          :href="sistaURL + '?code_du_territoire=' + codeParc"
          >Accéder au rapport</BoutonStandard
        >
      </template>
    </grilleStandard>
    <grilleStandard>
      <template #contenu>
        <fondChloropeth
          v-if="geojson && RGAData2020 && couleursPalette"
          :geojson="geojson"
          :key="cleCarte"
          :choisirCouleur="choisirCouleur"
          :montrerLegende="false"
          :onEachFeatureFunction="onEachFeatureFunction"
        />
      </template>
      <template #bordureDroite>
        <legendeChloropeth
          :couleursPalette="couleursPalette"
          titre="Légende"
          sens="column"
        />
      </template>
    </grilleStandard>
  </div>
</template>

<script>
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import fondChloropeth from "@packages/ui-components/src/composants/cartographie/fondChloropeth.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import legendeChloropeth from "@packages/ui-components/src/composants/cartographie/legendeChloropeth.vue";
import api_utils from "@/utils/api.js";
import otex_palette from "@packages/ui-components/src/donnees/otex_palette.json";
import geojson_api from "@packages/utils/GeoJsonReader.js";

export default {
  components: {
    fondChloropeth,
    grilleStandard,
    legendeChloropeth,
    BoutonStandard,
  },
  props: {
    codeParc: {
      type: String,
      default: "FR8000001",
    },
  },
  data() {
    return {
      geojson: null,
      carteChargement: false,
      cleCarte: "1",
      couleursPalette: null, // ajouter ca dans le composants de départ
      RGAData2020: null,
    };
  },
  methods: {
    async recupererGeojson() {
      geojson_api
        .getGeoJson(this.codeParc, "parc_naturel", "commune")
        .then((response) => {
          this.geojson = response;
          this.carteChargement = true;
        });
    },
    async recupererDonneesRGA() {
      api_utils
        .getDonneesRGACommunalesTerritoires(this.codeParc, 2020)
        .then((response) => {
          this.RGAData2020 = response;
        });
    },
    choisirCouleur(feature) {
      let donneeCommune =
        this.RGAData2020["resultats"]["OTEX principale"][
          feature.properties.code_commune
        ];
      if (donneeCommune === undefined) {
        return "#ffffff";
      } else {
        return this.trouverCouleur(donneeCommune);
      }
    },
    trouverCouleur(donneeCommune) {
      for (let i = 0; i < otex_palette.length; i++) {
        if (
          otex_palette[i]["label"].replace(/\s+/g, "") ===
          donneeCommune.replace(/\s+/g, "")
        ) {
          return otex_palette[i]["couleur"];
        }
      }
    },
  },
  async mounted() {
    await this.recupererGeojson();
    console.log("geojson", this.geojson);
    this.couleursPalette = otex_palette;
  },
  beforeMount() {
    this.recupererGeojson();
    this.recupererDonneesRGA();
  },

  computed: {
    onEachFeatureFunction() {
      return (feature, layer) => {
        let donneeCommune =
          this.RGAData2020["resultats"]["OTEX principale"][
            feature.properties.code_commune
          ];
        layer.bindTooltip(
          "<div>Nom de la commune:" +
            feature.properties.libelle_commune +
            "</br>" +
            "OTEX principale:" +
            donneeCommune +
            "</br>",
          { permanent: false, sticky: true }
        );
      };
    },
  },
};
</script>

<style scoped>
#legende-couleurs {
  display: flex;
  flex-direction: column;
}
</style>
