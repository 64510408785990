<template>
  <footer class="piedPage texte-petit">
    <router-link to="/mentions-legales">Mentions légales</router-link>
  </footer>
</template>

<style scoped>
.piedPage {
  background-color: var(--couleur-primaire);
  color: white;
  padding: 4px;
  text-align: right;
  a {
    color: white;
  }
  text-transform: uppercase;
}
</style>
