import api from "@packages/utils/BasicAPIReader.js";

api.setNewApiUrl("https://lebasic.nohost.me/api/");

async function getDonneesBasicTerritoires(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null
) {
  var url = `/basic/${code_territoire}`;
  if (annee && niveau_detail_geographique) {
    url += `?annee=${annee}&niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesTerritoires(
  code_territoire,
  niveau_detail_geographique = null
) {
  var url = `/territoires/${code_territoire}`;
  if (niveau_detail_geographique) {
    url += `?niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesRGATerritoires(
  code_territoire,
  annee,
  type_dimension_rga
) {
  var url = `/RGA/${type_dimension_rga}/${code_territoire}?annee=${annee}`;
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesRGACommunalesTerritoires(code_territoire, annee) {
  var url = `/RGA_par_commune/${code_territoire}?annee=${annee}`;
  var result = await api.serverCall(url);
  return result;
}

async function getDonneesRPGTerritoires(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null,
  niveau_detail_culture = null
) {
  var url = `/RPG/${code_territoire}`;
  if (annee && niveau_detail_geographique) {
    url += `?annee=${annee}&niveau_detail_geographique=${niveau_detail_geographique}`;
  } else if (annee && niveau_detail_culture) {
    url += `?annee=${annee}&niveau_detail_culture=${niveau_detail_culture}`;
  } else if (annee) {
    url += `?annee=${annee}`;
  }
  var result = await api.serverCall(url);
  return result;
}

export default {
  getDonneesBasicTerritoires,
  getDonneesRGATerritoires,
  getDonneesRGACommunalesTerritoires,
  getDonneesRPGTerritoires,
  getDonneesTerritoires,
};
