import { createRouter, createWebHistory } from "vue-router";
import pageAccueil from "@/pages/pageAccueil.vue";
import pageDashboard from "@/pages/synthese/pageDashboard.vue";
import pageContexte from "@/pages/pageContexte.vue";
import pageNavigationEtMethodologie from "@/pages/pageNavigationEtMethodologie.vue";
import pageMethodologie from "@/pages/pageMethodologie.vue";
import pageMentionsLegales from "@/pages/pageMentionsLegales.vue";
import protege from "@/pages/protege.vue";
import storageHelper from "storage-helper";

const routes = [
  {
    path: "/",
    name: "Page d'Accueil",
    component: pageAccueil,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tableau/:idParc",
    name: "Page Synthétique Parc",
    component: pageDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contexte",
    name: "Page Contexte",
    component: pageContexte,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/navigation",
    name: "Page Navigation",
    component: pageNavigationEtMethodologie,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/methodologie",
    name: "Page Methodologie",
    component: pageMethodologie,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/protege",
    name: "Page Protection",
    component: protege,
  },
  {
    path: "/mentions-legales",
    name: "Mentions Légales",
    component: pageMentionsLegales,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!storageHelper.getItem("user-password")) next("/protege");
    else next();
  } else next();
});
export default router;
