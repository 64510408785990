export const agregerChiffres = (dictionnaire) => {
  let valeurTotale = 0;
  for (let key in dictionnaire) {
    valeurTotale += dictionnaire[key];
  }
  return valeurTotale;
};

export const formatPercent = (amount, chiffreApresVirgule = 1) => {
  if (!amount) {
    return "-";
  }
  if (Math.abs(amount) < 0.001) {
    return amount.toLocaleString(undefined, {
      style: "percent",
      minimumSignificantDigits: chiffreApresVirgule,
      //maximumSignificantDigits:,
    });
  }
  const numberDigits =
    Math.abs(amount) < 0.01 ? 2 : Math.abs(amount) < 0.1 ? 1 : 0;
  return amount.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: numberDigits,
  });
};

export const formatNumber = (value) => {
  if (!value) {
    return "-";
  }
  let numberDigits = 0;
  let divisor = 1;
  let textUnit = "";
  if (Math.abs(value) > 1e9) {
    numberDigits = 1;
    divisor = 1e9;
    textUnit = "Billions";
  } else if (Math.abs(value) > 1e6) {
    numberDigits = 1;
    divisor = 1e6;
    textUnit = "Millions";
  } else if (Math.abs(value) > 1e3) {
    numberDigits = 1;
    divisor = 1e3;
    textUnit = "k";
  }
  return `${(value / divisor).toLocaleString(undefined, {
    maximumFractionDigits: numberDigits,
  })} ${textUnit}`;
};

function formatterNombreFormatFR(valeur, chiffreApresVirgule = 0) {
  if (!valeur) {
    return "-";
  }
  return valeur.toLocaleString("fr", {
    minimumFractionDigits: chiffreApresVirgule,
    maximumFractionDigits: chiffreApresVirgule,
  });
}

function formatterPourcentage(valeur, chiffreApresVirgule = 0) {
  if (!valeur) {
    return "-";
  }
  return valeur.toLocaleString("fr", {
    style: "percent",
    minimumFractionDigits: chiffreApresVirgule,
    maximumFractionDigits: chiffreApresVirgule,
  });
}

export default {
  agregerChiffres,
  formatPercent,
  formatNumber,
  formatterNombreFormatFR,
  formatterPourcentage,
};
