<template>
  <main class="container">
    <div id="chiffresSynthese">
      <menuOnglets v-if="renderComponent && codeParc">
        <onglet
          @click="forceRender"
          v-if="renderComponent"
          title="Fiche de Synthèse"
          ><grilleSynthesePNR v-if="codeParc" :codeParc="codeParc"
        /></onglet>
        <onglet
          @click="forceRender"
          v-if="renderComponent && codeParc"
          title="Part de la SAU par Commune"
          ><carteSAU v-if="codeParc" :codeParc="codeParc" />
        </onglet>
        <onglet
          @click="forceRender"
          v-if="renderComponent && codeParc"
          title="Carte des OTEX par commune"
          ><carteOTEX v-if="codeParc" :codeParc="codeParc" />
          <InformationTooltip />
        </onglet>
      </menuOnglets>
    </div>
  </main>
</template>
<script>
import carteOTEX from "./carteOTEX.vue";
import carteSAU from "./carteSAU.vue";
import grilleChiffres from "@packages/ui-components/src/composants/visualisation/grilleChiffres.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import grilleSynthesePNR from "./grilleSynthesePNR.vue";
import InformationTooltip from "@packages/ui-components/src/composants/basNiveau/InformationTooltip.vue";
import menuOnglets from "@packages/ui-components/src/composants/navigation/menuOnglets.vue";
import onglet from "@packages/ui-components/src/composants/navigation/Onglet.vue";

export default {
  name: "pageTestGrille",
  components: {
    carteOTEX,
    carteSAU,
    grilleChiffres,
    grilleStandard,
    grilleSynthesePNR,
    InformationTooltip,
    menuOnglets,
    onglet,
  },
  data() {
    return {
      renderComponent: true,
      componentKey: 0,
      codeParc: null,
    };
  },
  methods: {
    forceRender() {
      this.componentKey += 1;
      console.log("test onglet reload", this.componentKey);
    },
  },
  mounted() {
    this.codeParc = this.$route.params.idParc;
    console.log("codeParc before mount", this.codeParc);
  },
};
</script>

<style>
#agri {
  background-color: red;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
#bio {
  background-color: white;
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.carte {
  background-color: blue;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
  padding: var(--dsem2) 0;
}

#chiffresSynthese {
  flex-basis: 100%;
}

@layer base {
  :root {
    --hauteur-barre-navigation: 50px;
  }
}
</style>
