import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@packages/ui-components/src/index.css";
import "@/pnr.css";

const app = createApp(App);
app.config.globalProperties.sistaURL = "https://sista.lebasic.com/pnr/sista";

app.use(store).use(router).mount("#app");
