<script>
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
export default {
  components: { grilleStandard },
};
</script>

<template>
  <grilleStandard>
    <template v-slot:contenu>
      <article class="contenu">
        <h2>Contexte</h2>
        <p>
          Depuis 2019, la Fédération des Parcs naturels régionaux, le réseau des
          Parcs nationaux et l’Office Français de la Biodiversité ont développé
          une réflexion commune autour des politiques agricoles et des outils
          d’accompagnement de la transition agroécologique.
        </p>
        <p>
          En 2022, la Fédération des PNR de France et le réseau des Parcs
          nationaux ont eu l’ambition de construire un observatoire de
          l’agroécologie et de l’alimentation dans les Parcs, afin de présenter
          de façon claire et visuelle un ensemble d’indicateurs régulièrement
          mis à jour sur les systèmes agricoles et alimentaires des Parcs.
        </p>
        <br />
        Cet observatoire répond à plusieurs objectifs :
        <ul>
          <li>
            Mieux connaître les systèmes agricoles et alimentaires dans les
            territoires de Parc : tendances, évolutions, spécificités, etc.
          </li>
          <li>
            Faire le lien avec les actions des Parcs : mesures
            agroenvironnementales, développement de l’agriculture biologique,
            entretien des prairies et des haies, marques Parc, accompagnement
            des filières, Plans alimentaires territoriaux, etc.
          </li>
          <li>
            Communiquer sur les avancées ainsi que les besoins des Parcs au
            regard de la transition agroécologique et alimentaire.
          </li>
        </ul>
        <h2>Porteurs de l’observatoire</h2>
        <img
          :src="require('@/assets/images/logo_financeurs/Logo_pnr.png')"
          width="100"
        />
        <p>
          Les Parcs naturels régionaux, au nombre de 58 en 2021, sont créés pour
          protéger et mettre en valeur de grands espaces ruraux habités. Un parc
          naturel régional s’organise autour d’un projet concerté de
          développement et de protection défini pour 15 ans, la charte. Les
          dispositions principales concernant les Parcs naturels régionaux sont
          codifiées aux articles L.333-1 à L. 333-16 du code de l’environnement.
          Les actions d'un Parc naturel régional sont mises en œuvre par un
          syndicat mixte ouvert. Chaque Parc naturel régional est un organisme
          indépendant administrativement et financièrement.
        </p>
        <p>
          Chaque Parc naturel régional, sur la base d’une charte renouvelée tous
          les 15 ans, construit un projet de territoire basé sur 5 missions :
        </p>
        <ul>
          <li>Protection du patrimoine naturel et culturel</li>
          <li>Accueil, information et éducation du public</li>
          <li>Contribution à l'aménagement du territoire</li>
          <li>
            Contribution au développement économique, social, culturel et à la
            qualité de vie
          </li>
          <li>Expérimentation de démarches innovantes.</li>
        </ul>

        <p>
          La Fédération des Parcs naturels régionaux de France est le
          porte-parole du réseau des Parcs naturels régionaux (PNR).
        </p>
        <p>
          <a class="lien" href="https://www.parcs-naturels-regionaux.fr/"
            >Site de la Fédération des parcs naturels régionaux de France</a
          >
        </p>
        <p>
          Les Parcs nationaux français, au nombre de 11, abritent des
          territoires remarquables pour leur patrimoine paysager, naturel et
          culturel. Ce sont des établissements publics de l’État placés sous la
          tutelle du Ministère de la transition écologique (MTE) et rattachés à
          l’Office français de la biodiversité (OFB).
        </p>
        <p>
          Un Parc national comprend deux zones aux statuts réglementaires
          différents :
        </p>
        <ul>
          <li>
            Le cœur, espace réglementé : le Parc national en garantit la
            protection selon ses décrets et maîtrise les activités humaines qui
            s’y exercent, en cohérence avec ses objectifs de gestion.
          </li>
          <li>
            L’aire d’adhésion, espace de projet : espace de cohérence et de
            solidarité écologique avec le cœur du Parc national. Le Parc
            national y joue un rôle de conseil ou de partenaire dans la conduite
            de projets fondés sur la préservation et la valorisation du
            patrimoine naturel, culturel et paysager.
          </li>
        </ul>
        <p>
          Construite collectivement avec les communes et les acteurs du
          territoire, la charte indique pour 15 ans les orientations de
          protection, de mise en valeur et de développement durable retenues
          pour le territoire, en cœur comme en aire d'adhésion, dont le
          périmètre est constitué par les territoires des communes qui y
          adhèrent.
        </p>
        <p>
          <a class="lien" href="https://parcsnationaux.fr/fr"
            >Portail des Parcs nationaux</a
          >
        </p>
        <p>
          Le BASIC (Bureau d'Analyse Sociétale d'Intérêt collectif) est une SCIC
          rassemblant une douzaine de salariés. L’objectif principal du BASIC
          est de contribuer au changement des systèmes de production et de
          consommation actuels afin de limiter leurs impacts sociaux et
          environnementaux et de rendre possible une transition écologique et
          sociale à la hauteur des enjeux actuels.
        </p>
        <p>
          Via ses activités, le bureau d’études fournit des clés de lecture et
          des informations pour éclairer les choix individuels et collectifs,
          au-delà des informations déjà disponibles (certifications,
          labellisations, notations produits, RSE, notations extra-financières…)
        </p>

        <p>Il s'agit notamment de permettre:<br>
        <ul>
          <li>Aux pouvoirs publics, collectivités, institutions, acteurs économiques d’identifier et mesurer les impacts sociétaux de leur stratégie et choix budgétaires, afin d’accompagner la transition écologique sur leurs territoires et/ou secteurs d’activité.
          </li>
          <li>Aux citoyens de mieux comprendre les principaux enjeux sociétaux associés à leurs choix de consommation, de carrière, d’investissement. </li>
        </ul></p>
        <p>Les activités du BASIC activités, initialement développées en réponse à des demandes d’acteurs de la société civile – français et internationaux – pour objectiver leur plaidoyer, se sont progressivement élargies à l’attention des institutions publiques et parapubliques, aux interprofessions d’acteurs économiques et plus récemment aux collectivités locales. </p>

        <p>Un des partis pris de la SCIC est le développement d'outils numériques afin de mettre à disposition des acteurs des outils interactifs, rassemblant l'ensemble des données mobilisées dans le cadre des études. </p><h2>Partenaires financiers</h2>
        <p>
          Cet observatoire a été réalisé grâce au soutien financier de l’Office
          français de la biodiversité, du Ministère de la transition écologique
          et de la cohésion des territoires ainsi que de la Fédération des Parcs
          naturels régionaux.
        </p>
        <div class="logo-financeurs">
          <img :src="require('@/assets/images/logo_financeurs/Logo_pnr.png')" />
          <img :src="require('@/assets/images/logo_financeurs/logo-ofb.png')" />
          <img
            :src="
              require('@/assets/images/logo_financeurs/Ministère_de_la_Transition_écologique.svg')
            "
          />
        </div>
      </article>
    </template>
  </grilleStandard>
</template>

<style scoped>
.contenu {
  text-align: left;
  h1,
  h2,
  p,
  ul {
    padding: var(--dsem);
  }
  ul {
    list-style: circle;
    list-style-position: inside;
  }
}

.logo-financeurs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.logo-financeurs img {
  max-height: 200px;
  margin: auto;
}

.lien {
  color: var(--couleur-primaire);
  font-weight: bold;
}
</style>
