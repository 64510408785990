<template>
  <div id="container">
    <grilleStandard style="height: fit-content; padding: var(--dsem2) 0">
      <template #contenu>
        <h1>Carte de la Surface Agricole Utile par commune: RPG 2020</h1>
      </template>
      <template #bordureDroite>
        <BoutonStandard
          typeBouton="lien"
          :href="sistaURL + '?code_du_territoire=' + codeParc"
          >Accéder au rapport</BoutonStandard
        >
      </template>
    </grilleStandard>
    <grilleStandard>
      <template #contenu>
        <fondChloropeth
          v-if="geojson && RPGData2020 && DataTerritoires && couleursPalette"
          :geojson="geojson"
          :key="cleCarte"
          :choisirCouleur="choisirCouleur"
          couleurBordure="#006d2c"
          :montrerLegende="false"
          :onEachFeatureFunction="onEachFeatureFunction"
        />
      </template>
      <template #bordureDroite>
        <legendeChloropeth
          :couleursPalette="couleursPalette"
          titre="Légende"
          sens="column"
        />
      </template>
    </grilleStandard>
  </div>
</template>

<script>
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";
import fondChloropeth from "@packages/ui-components/src/composants/cartographie/fondChloropeth.vue";
import grilleStandard from "@packages/ui-components/src/composants/grille/grilleStandard.vue";
import legendeChloropeth from "@packages/ui-components/src/composants/cartographie/legendeChloropeth.vue";
import api_utils from "@/utils/api.js";
import formatter_utils from "@/utils/formatter.js";
import geojson_api from "@packages/utils/GeoJsonReader.js";

export default {
  components: {
    fondChloropeth,
    grilleStandard,
    legendeChloropeth,
    BoutonStandard,
  },
  props: {
    codeParc: {
      type: String,
      default: "FR8000001",
    },
  },
  data() {
    return {
      geojson: null,
      carteChargement: false,
      cleCarte: "1",
      couleursPalette: [
        {
          couleur: "#006d2c",
          label: "+ de 80% de SAU",
          borne_superieure: 1,
          borne_inferieure: 0.8,
        },
        {
          couleur: "#2ca25f",
          label: "60% à 80% de SAU",
          borne_superieure: 0.8,
          borne_inferieure: 0.6,
        },
        {
          couleur: "#66c2a4",
          label: "40% à 60% de SAU",
          borne_superieure: 0.6,
          borne_inferieure: 0.4,
        },
        {
          couleur: "#b2e2e2",
          label: "20% à 40% de SAU",
          borne_superieure: 0.4,
          borne_inferieure: 0.2,
        },
        {
          couleur: "#edf8fb",
          label: "0% à 20% de SAU",
          borne_superieure: 0.2,
          borne_inferieure: 0,
        },
        {
          couleur: "#bbbdc0",
          label: "Pas de données",
          borne_superieure: 0,
          borne_inferieure: 0,
        },
      ],
      RPGData2020: null,
      DataTerritoires: null,
    };
  },
  methods: {
    async recupererGeojson() {
      geojson_api
        .getGeoJson(this.codeParc, "parc_naturel", "commune")
        .then((response) => {
          this.geojson = response;
          this.carteChargement = true;
        });
    },
    async recupererDonnees() {
      this.RPGData2020 = await api_utils.getDonneesRPGTerritoires(this.codeParc, 2020, "commune", null)
      this.DataTerritoires = await api_utils.getDonneesTerritoires(this.codeParc, "commune")
    },
    choisirCouleur(feature) {
      let codeCommune = feature.properties.code_commune;
      let surface =
        this.DataTerritoires["resultats"]["Superficie totale du territoire"][
          `com${codeCommune}`
        ];
      let sau =
        this.RPGData2020["resultats"]["Surface Agricole Utile"][codeCommune];
      let part_sau = sau / surface;
      let couleur = "#ffffff";
      this.couleursPalette.forEach((palette) => {
        if (
          (part_sau < palette["borne_superieure"]) &
          (part_sau >= palette["borne_inferieure"])
        ) {
          couleur = palette["couleur"];
        }
      });
      return couleur;
    },
  },
  computed: {
    onEachFeatureFunction() {
      return (feature, layer) => {
        let codeCommune = feature.properties.code_commune;
        let surface =
          this.DataTerritoires["resultats"]["Superficie totale du territoire"][
            `com${codeCommune}`
          ];
        let sau =
          this.RPGData2020["resultats"]["Surface Agricole Utile"][codeCommune];
        let part_sau = sau / surface;
        layer.bindTooltip(
          "<div>Nom de la commune:" +
            feature.properties.libelle_commune +
            "</br>" +
            "Part de la SAU:" +
            formatter_utils.formatPercent(part_sau ?? 0, 1) +
            "</br>",
          { permanent: false, sticky: true }
        );
      };
    },
  },
  async mounted() {
    await this.recupererGeojson();
    console.log("geojson", this.geojson);
  },
  beforeMount() {
    this.recupererGeojson();
    this.recupererDonnees();
  },
};
</script>

<style scoped>
#container {
  height: calc(100vh - var(--hauteur-barre-navigation) - var(--dsem2) - 52px);
  display: flex;
  flex-direction: column;
  gap: var(--dsem2);
}

#legende-couleurs {
  display: flex;
  flex-direction: column;
}
</style>
