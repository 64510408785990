<template>
  <div class="carte">
    <l-map ref="map" style="" :zoom="zoom" :center="center" id="map">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <l-marker
        v-for="parc in liste_pnr"
        :key="parc.nom"
        :lat-lng="[parc.latitude_centroid, parc.longitude_centroid]"
      >
        <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
        <l-popup>
          <h4>{{ parc.nom }}</h4>
          <BoutonStandard
            taille="texte-petit"
            typeBouton="bouton"
            :lienRoute="{
              name: 'Page Synthétique Parc',
              params: { idParc: parc.code },
            }"
            class="boutonPopup"
            >Lien vers la page de synhtèse</BoutonStandard
          >
        </l-popup>
      </l-marker>
      <l-marker
        v-for="parc in liste_pnx"
        :key="parc.nom"
        :lat-lng="[parc.latitude_centroid, parc.longitude_centroid]"
      >
        <l-icon :icon-url="iconurl2" :icon-size="iconSize" />
        <l-popup>
          <h4>{{ parc.nom }}</h4>
          <BoutonStandard
            taille="texte-petit"
            typeBouton="bouton"
            :lienRoute="{
              name: 'Page Synthétique Parc',
              params: { idParc: parc.code },
            }"
            class="boutonPopup"
            >Lien vers la page de synhtèse</BoutonStandard
          >
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import liste_parcs from "@/assets/donnees/liste_parcs.json";
import parcImage from "@/assets/images/favicon.png";
import pnxImage from "@/assets/images/PN/parcs_nationaux.png";
import BoutonStandard from "@packages/ui-components/src/composants/BoutonStandard.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    BoutonStandard,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [46.2276, 2.2137],
      liste_parcs,
      iconSize: [24, 24],
      iconUrl: parcImage,
      iconurl2: pnxImage,
      liste_pnr: [],
      liste_pnx: [],
    };
  },
  beforeMount() {
    this.liste_pnr = liste_parcs.filter((parc) => {
      return parc.type === "pnr";
    });
    this.liste_pnx = liste_parcs.filter((parc) => {
      return parc.type === "pnx";
    });
  },
  computed: {
    contenuPopup() {
      let test = this.liste_parcs.map((parc) => {
        return `<h2>${parc.nom}</h2>`;
      });
      console.log(test);
      return test;
    },
  },
};
</script>

<style>
.carte {
  flex: 1;
  display: flex;
}
#map {
  flex: 1;
  z-index: 0;
}

.boutonPopup a {
  color: white !important;
}

.leaflet-container a {
  color: white;
}
</style>
