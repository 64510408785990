<template>
  <div id="grille-chiffres">
    <slot></slot>
  </div>
</template>

<style scoped>
#grille-chiffres {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: var(--dsem2);
  margin: auto;
  width: 100%;
  height: 100%;
}
</style>
