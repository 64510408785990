<template>
  <grilleStandard class="menu-onglet">
    <template #contenu>
      <ul class="menuListe">
        <li
          :class="{
            ongletActif: onglet.hash === activeOngletHash,
          }"
          v-for="onglet in onglets"
          :key="onglet.title"
          @click="
            activeOngletHash = onglet.hash;
            forceRender();
          "
          class="texte-petit"
        >
          {{ onglet.title }}
        </li>
      </ul>
    </template>
  </grilleStandard>
  <grilleStandard id="contenu-tab">
    <template v-slot:contenu>
      <slot />
    </template>
  </grilleStandard>
</template>

<script>
import { computed } from "vue";
import grilleStandard from "../grille/grilleStandard.vue";

export default {
  name: "menuOnglets",
  components: {
    grilleStandard,
  },
  data() {
    return {
      activeOngletHash: "",
      onglets: [],
      renderComponent: true,
      componentKey: 0,
    };
  },
  provide() {
    return {
      ajouterOnglet: (onglet) => {
        const count = this.onglets.push(onglet);

        if (count === 1) {
          this.activeOngletHash = onglet.hash;
        }
      },
      activeOngletHash: computed(() => this.activeOngletHash),
    };
  },
  methods: {
    forceRender() {
      this.componentKey += 1;
      console.log("test onglet reload", this.componentKey);
    },
  },
};
</script>

<style scoped>
.menu-onglet {
  background-color: #ebebeb;
  height: fit-content !important;
}

.menuListe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--dsem2);
}

.menuListe li {
  min-width: 150px;
  cursor: pointer;
  padding: var(--dsem2);
  text-transform: uppercase;
  color: #c1c1c1;
}

.ongletActif {
  background-color: white;
  color: black !important;
}
</style>
