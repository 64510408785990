<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="32px"
    y="32px"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 32 32"
    xml:space="preserve"
    class="fleche"
  >
    <g>
      <polyline class="st0" points="17.7,3.5 30.1,16 17.7,28.5 	" />
      <line class="st0" x1="30.1" y1="16" x2="1.9" y2="16" />
    </g>
  </svg>
</template>

<style type="text/css">
.st0 {
  fill: none;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
}

.fleche {
  width: 100%;
  height: 100%;
}
</style>
