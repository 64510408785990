<template>
  <EnTete :ElementsMenu="this.ElementsMenu" :imagesLogo="imageLogo"> </EnTete>
  <RouterView />
  <piedPage />
</template>

<script>
import EnTete from "@packages/ui-components/src/composants/EnTete.vue";
import logoPNR from "@/assets/images/PN/pnr.svg";
import logoPN from "@/assets/images/PN/parcs_nationaux.png";
import logoBasic from "@packages/ui-components/src/assets/logos/basic/BASIC_logo-mention-4_noir.svg";
import maison from "@packages/ui-components/src/assets/icon/maison.svg";
import piedPage from "./composants/piedPage.vue";
export default {
  name: "App",
  components: {
    EnTete,
    piedPage,
  },
  data() {
    return {
      ElementsMenu: [
        { chemin: "/", libelle: "Accueil", icon: maison },
        { chemin: "/contexte", libelle: "Contexte" },
        { chemin: "/methodologie", libelle: "Methodologie" },
        { chemin: "/navigation", libelle: "Navigation" },
      ],
      imageLogo: [logoBasic, logoPNR, logoPN],
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a.router-link-exact-active {
  color: var(--couleur-primaire);
  height: 100%;
  align-content: center;
  img {
    filter: invert(38%) sepia(93%) saturate(539%) hue-rotate(122deg)
      brightness(94%) contrast(101%);
  }
}

.titreNav {
  color: white;
  font-size: 24px;
}

.titreNav {
  color: var(--couleur-primaire) !important;
}

.menuelements {
  font-size: 14px;
  font-weight: 600;
  color: var(--couleur-gris);
}

.image-element-menu {
  filter: invert(85%) sepia(0%) saturate(731%) hue-rotate(280deg)
    brightness(94%) contrast(81%);
}
</style>
